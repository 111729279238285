import { Match } from "@hellodarwin/core/lib/features/entities";
import theme from "@hellodarwin/core/lib/theme";

const getStatusColor = (status: Match["status"]) => {
  switch (status) {
    case "new":
      return theme.colors.purple_1;
    case "waiting":
    case "warning":
      return theme.colors.yellow_1;
    case "ignored":
    case "archive":
    case "finalist":
      return theme.colors.grey_2;
    case "winner":
    case "shortlisted":
    case "bought":
      return theme.colors.green_1;
    case "rejected":
      return theme.colors.red_1;

    default:
      return theme.colors.beige_1;
  }
};

export default getStatusColor;

