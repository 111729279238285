import Button from "@hellodarwin/core/lib/components/common/button";
import DesktopNavigation from "@hellodarwin/core/lib/components/layout/navigation/desktop-navigation";
import MobileNavigation from "@hellodarwin/core/lib/components/layout/navigation/mobile-navigation";
import { NavSider } from "@hellodarwin/core/lib/components/layout/navigation/styles";
import { NavigationLink } from "@hellodarwin/core/lib/features/entities";
import SingleLanguage from "@hellodarwin/core/lib/features/enums/single-language";
import getAgenciesPage from "@hellodarwin/core/lib/features/helpers/get-agencies-page";
import useMediaQuery from "@hellodarwin/core/lib/features/helpers/use-media-query";
import { useAppData } from "@hellodarwin/core/lib/features/providers/app-provider";
import { useLogin } from "@hellodarwin/core/lib/features/providers/login-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import { DefaultTheme } from "@hellodarwin/core/lib/theme/index";
import Accueil from "@hellodarwin/icons/dist/icons/Accueil";
import ArrowOutline from "@hellodarwin/icons/dist/icons/ArrowOutline";
import Employees from "@hellodarwin/icons/dist/icons/Employees";
import Grants from "@hellodarwin/icons/dist/icons/Grants";
import Help from "@hellodarwin/icons/dist/icons/Help";
import ProjectsList from "@hellodarwin/icons/dist/icons/ProjectsList";
import Refereral from "@hellodarwin/icons/dist/icons/Refereral";
import Settings from "@hellodarwin/icons/dist/icons/Settings";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../app/app-hooks";
import { selectProfile } from "../features/api/slices/profile-slice";
import { usePartnerApi } from "../features/api/use-partner-api";
import convertProviderToProfile from "../features/helpers/convert-provider-profile";
import showErrorNotification from "../features/helpers/show-error-notifications";

const Navigation = () => {
  const api = usePartnerApi();
  const { t } = useTranslation();
  const theme = useTheme() as DefaultTheme;
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
  const { handleLogout } = useLogin();

  const profile = useAppSelector(selectProfile);

  const { navCollapsed, handleNavCollapse } = useAppData();

  const [publicProfileUrl, setPublicProfileUrl] = useState<string>("");
  useEffect(() => {
    (async () => {
      getAgenciesPage({
        provider: profile,
        locale: profile.profile_language as SingleLanguage,
      }).then((page) => {
        setPublicProfileUrl(page);
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  //Functions
  const openBillingSession = () => {
    (async () => {
      try {
        const session = await api.fetchBillingSession();
        window.location.href = session.url;
      } catch (err: any) {
        showErrorNotification(err.response.data.error_code);
      }
    })();
  };

  const OpenFundingExplorer = () => {
    (async () => {
      try {
        window.open(
          "https://app.hellodarwin.com/funding-explorer?utm_source=helloDarwin&utm_medium=partner-app&utm_campaign=partner-nav",
          "_blank"
        );
      } catch (err: any) {
        showErrorNotification(err.response.data.error_code);
      }
    })();
  };

  const links: NavigationLink[] = [
    {
      label: t("navigation.dashboard"),
      pathname: "/dashboard",

      Icon: Accueil,
    },
    {
      pathname: "/projects",
      label: t("navigation.opportunities"),
      Icon: ProjectsList,
      subLinks: [
        {
          pathname: "/projects/new",
          label: t("navigation.newOpportunities"),
        },
        {
          pathname: "/projects/ongoing",
          label: t("navigation.onGoingOpportunities"),
        },
        {
          pathname: "/projects/closed",
          label: t("navigation.completedOpportunities"),
        },
      ],
    },
    {
      label: t("navigation.profile"),
      pathname: "/profile",
      Icon: Employees,
      subLinks: [
        {
          label: t("navigation.edit"),
          pathname: "/profile/edit",
        },
        {
          label: t("navigation.publicProfile"),
          isExternal: true,
          pathname: "/profile/external",
          external_link: publicProfileUrl,
          hide: !publicProfileUrl,
        },
        {
          label: t("navigation.expertise"),
          pathname: "/expertise",
        },
        {
          label: t("navigation.reviews"),
          pathname: "/reviews",
          isNew: true,
        },
      ],
    },
    {
      pathname: "/referral",
      label: t("navigation.referral"),
      Icon: Refereral,
      subLinks: [
        {
          pathname: "/referral/myreferrals",
          label: t("navigation.myReferral"),
        },
        {
          pathname: "/referral/refer",
          label: t("navigation.referralRefer"),
        },
      ],
    },
    {
      Icon: Grants,
      pathname: "/billing",
      label: t("navigation.billing"),
      isExternal: true,
      onClick: openBillingSession,
    },
    {
      pathname: "funding-explorer",
      label: t("navigation.fundingExplorer"),
      Icon: Grants,
      isExternal: true,
      onClick: OpenFundingExplorer,
    },
    {
      pathname: "/faq",
      label: t("navigation.faq"),
      Icon: Help,
    },
  ];

  //User Links
  const userLinks: NavigationLink[] = [
    {
      label: t("navigation.settings"),
      pathname: "/settings",
      Icon: Settings,
    },
  ];

  const navigate = useNavigate();
  const { pathname } = useLocation();

  return isTablet ? (
    <MobileNavigation
      links={links}
      profile={convertProviderToProfile(profile)}
      handleLogout={handleLogout}
      userLinks={userLinks}
      navigate={navigate}
      pathname={pathname}
      app="provider"
    />
  ) : (
    <NavSider
      collapsible
      collapsed={navCollapsed}
      onCollapse={handleNavCollapse}
      width={theme.appearance.sidebarWidth}
      style={{ top: 0, position: "sticky", height: "100vh" }}
      trigger={
        <Button
          fitContent
          style={{ padding: 0 }}
          transparent
          defaultStyle={theme.colors.white_1}
          onClick={handleNavCollapse}
        >
          <ArrowOutline
            style={{
              transform: navCollapsed ? "rotate(200deg)" : "rotate(0deg)",
              transition: "all 100ms linear",
            }}
          />
        </Button>
      }
    >
      <DesktopNavigation
        navigate={navigate}
        pathname={pathname}
        links={links}
        app="provider"
      />
    </NavSider>
  );
};

export default Navigation;

