import Div from "@hellodarwin/core/lib/components/common/div";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import ProfileEditModal, {
  ProfileEditModalFunctions,
} from "@hellodarwin/core/lib/components/forms/prebuilt-forms/profile-edit-modal";
import ProviderProfile from "@hellodarwin/core/lib/components/provider/profile";
import { ProfileModalProps } from "@hellodarwin/core/lib/features/entities";
import ProfileBlocks from "@hellodarwin/core/lib/features/enums/profile-blocks";
import parse from "@hellodarwin/core/lib/features/helpers/parse";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import Select from "antd/es/select";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/app-hooks";
import {
  selectProfileModal,
  toggleProfileModal,
} from "../features/api/slices/global-slice";
import {
  createTags,
  deleteBanner,
  deleteLogo,
  deleteVideos,
  editTeamMember,
  fetchPortfolios,
  fetchProfile,
  fetchTags,
  fetchTeamMembers,
  handleServiceChange,
  onboardingMatch,
  removePortfolio,
  removeTeamMember,
  selectPortfolioById,
  selectPortfolios,
  selectProfile,
  selectProfileLoading,
  selectTags,
  selectTeamMembers,
  submitPortfolio,
  submitTeamMember,
  toggleCategoryTag,
  toggleServiceTag,
  toggleSpecialtyTag,
  updateBanner,
  updateLogo,
  updatePortfolio,
  updateProfile,
  updateVideos,
} from "../features/api/slices/profile-slice";
import {
  fetchWonProjects,
  selectAllProject,
  selectProjectsLoading,
} from "../features/api/slices/projects-slice";
import {
  fetchReviews,
  selectAllReviews,
} from "../features/api/slices/reviews-slice";
import { usePartnerApi } from "../features/api/use-partner-api";

type ProfileState = "edit" | "website" | "client";
const ProviderProfilePreviewPage = ({
  defaultState = "edit",
}: {
  defaultState?: ProfileState;
}) => {
  const dispatch = useAppDispatch();
  const api = usePartnerApi();
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();

  const provider = useAppSelector(selectProfile);
  const teamMembers = useAppSelector(selectTeamMembers);
  const projects = useAppSelector(selectAllProject);
  const projectsLoading = useAppSelector(selectProjectsLoading);
  const portfolios = useAppSelector(selectPortfolios);
  const reviews = useAppSelector(selectAllReviews);
  const isLoading = useAppSelector(selectProfileLoading);
  const tags = useAppSelector(selectTags);

  // Fetch profile-related data
  useEffect(() => {
    dispatch(fetchProfile({ api }));
    dispatch(fetchTeamMembers({ api }));
    dispatch(fetchPortfolios({ api }));
    dispatch(fetchTags({ api }));
    dispatch(fetchWonProjects({ api }));
    dispatch(fetchReviews({ api }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProfileModal = (props: ProfileModalProps) => {
    dispatch(toggleProfileModal(props));
  };

  //MODAL VARIABLES
  const modal = useAppSelector(selectProfileModal);
  const handleCloseModal = () => {
    dispatch(
      toggleProfileModal({ open: false, targetId: undefined, type: undefined })
    );
  };

  const activePortfolio = useAppSelector((state) =>
    selectPortfolioById(state, modal.targetId || "")
  );

  const functions: ProfileEditModalFunctions = {
    isLoading: isLoading,
    fetchProfile: () => dispatch(fetchProfile({ api })),
    updateProfile: ({ data }) => dispatch(updateProfile({ api, data })),
    submitPortfolio: ({ data }) => dispatch(submitPortfolio({ api, data })),
    updatePortfolio: ({ data }) => dispatch(updatePortfolio({ api, data })),
    removePortfolio: ({ portfolioID }) =>
      dispatch(removePortfolio({ api, portfolioID })),
    updateBanner: ({ companyId, data }) =>
      dispatch(updateBanner({ api, data, companyId })),
    updateLogo: ({ companyId, data }) =>
      dispatch(updateLogo({ api, data, companyId })),
    updateVideos: ({ companyId, data }) =>
      dispatch(updateVideos({ api, data, companyId })),
    submitTeamMember: ({ data }) => dispatch(submitTeamMember({ api, data })),
    editTeamMember: ({ data, teamMemberId }) =>
      dispatch(editTeamMember({ api, data, teamMemberId })),
    removeTeamMember: ({ teamMemberID }) =>
      dispatch(removeTeamMember({ api, teamMemberID })),
    deleteBanner: ({ companyId }) => dispatch(deleteBanner({ api, companyId })),
    deleteLogo: ({ companyId }) => dispatch(deleteLogo({ api, companyId })),
    deleteVideos: ({ companyId }) => dispatch(deleteVideos({ api, companyId })),
    handleServiceChange: ({ service, minBudget, maxBudget, description }) =>
      dispatch(
        handleServiceChange({ service, minBudget, maxBudget, description })
      ),
    createTags: ({ serviceTags, specialtyTags }) =>
      dispatch(createTags({ api, serviceTags, specialtyTags })),
    onboardingMatch: () => dispatch(onboardingMatch({ api })),
    toggleCategoryTag: (category: string) =>
      dispatch(toggleCategoryTag(category)),
    toggleServiceTag: ({ service }) => dispatch(toggleServiceTag({ service })),
    toggleSpecialtyTag: ({ specialty }) =>
      dispatch(toggleSpecialtyTag({ specialty })),
  };

  const navigate = useNavigate();
  const handleState = (newState: ProfileState) => {
    navigate(`/profile/${newState}`);
  };
  const handleRedirectToSettings = () => navigate(`/settings/company`);

  return (
    <>
      <PageLayout
        app="provider"
        title=""
        className="partner-profile-preview-page"
        actions={
          <Select
            options={[
              {
                label: parse(t("provider-profile|viewTypes.edit")),
                value: "edit",
              },
              {
                label: parse(t("provider-profile|viewTypes.client")),
                value: "client",
              },
              {
                label: parse(t("provider-profile|viewTypes.website")),
                value: "website",
              },
            ]}
            size="large"
            style={{ width: 250 }}
            onChange={handleState}
            value={defaultState}
          />
        }
        breadcrumbs={[
          {
            breadcrumbName: t("navigation.home"),
            path: "/",
          },
          {
            breadcrumbName: t("navigation.profile"),
            path: "/profile",
          },
          {
            breadcrumbName: t(`navigation.${defaultState}`),
          },
        ]}
      >
        <Div flex="column" align="center">
          <ProviderProfile
            provider={provider}
            state={defaultState}
            reviews={reviews}
            portfolios={portfolios}
            teamMembers={teamMembers}
            projects={{ isLoading: projectsLoading, projects: projects }}
            toggleEditModal={handleProfileModal}
            handleRedirectToSettings={handleRedirectToSettings}
            sections={
              defaultState === "website"
                ? [
                    ProfileBlocks.FastInfos,
                    ProfileBlocks.About,
                    ProfileBlocks.Presentation,
                    ProfileBlocks.Portfolios,
                    ProfileBlocks.Expertise,
                    ProfileBlocks.Reviews,
                    ProfileBlocks.Project,
                    ProfileBlocks.Team,
                    ProfileBlocks.Contact,
                  ]
                : [
                    ProfileBlocks.FastInfos,
                    ProfileBlocks.Presentation,
                    ProfileBlocks.Portfolios,
                    ProfileBlocks.About,
                    ProfileBlocks.Reviews,
                    ProfileBlocks.Team,
                    ProfileBlocks.Expertise,
                    ProfileBlocks.Contact,
                  ]
            }
            locale={selectedLocale}
          />
        </Div>
      </PageLayout>
      <ProfileEditModal
        profile={provider}
        modal={modal}
        handleCancel={handleCloseModal}
        functions={functions}
        tags={tags}
        teamMembers={teamMembers}
        activePortfolio={activePortfolio}
      />
    </>
  );
};

export default ProviderProfilePreviewPage;

