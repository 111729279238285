import { CORE_NAMESPACES } from "@hellodarwin/core/lib/features/entities/translations_ns";
import {
  DEFAULT_LANGUAGE,
  LOCALE_CACHE_KEY,
  SUPPORTED_LANGUAGES,
} from "@hellodarwin/core/lib/features/providers/locale-provider";
import {
  HttpBackend,
  LanguageDetector,
  i18n,
  initReactI18next,
  resourcesToBackend,
} from "@hellodarwin/core/lib/plugins/i18n/provider";
import "dayjs/locale/fr";

const NAMESPACES = [
  "expertise",
  "faq",
  "modal",
  "onboarding",
  "payment",
  "referral",
  "project",
  "dashboard",
  ...CORE_NAMESPACES,
];

// Initialize i18n
i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(
    resourcesToBackend(async (language: string, namespace: string) => {
      try {
        if (CORE_NAMESPACES.includes(namespace)) {
          return await require(
            `../../content/locales/${language}/${namespace}.json`
          );
        } else {
          return await require(
            `../public/locales/${language}/${namespace}.json`
          );
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    })
  )
  .init({
    ns: NAMESPACES,
    defaultNS: "common",
    lng: getLanguage(),
    fallbackLng: DEFAULT_LANGUAGE,
    nsSeparator: "|",
    react: {
      useSuspense: false,
    },
  });

// Get the user's language preference
export function getLanguage() {
  const storedLanguage = localStorage.getItem(LOCALE_CACHE_KEY) || "en";
  const browserLanguage = navigator.language;
  return SUPPORTED_LANGUAGES.includes(storedLanguage)
    ? storedLanguage
    : browserLanguage.startsWith("fr")
      ? "fr"
      : DEFAULT_LANGUAGE;
}

// Load language files for specified namespaces
export async function loadLanguageFiles(namespaces: string[]) {
  try {
    await i18n.loadNamespaces(namespaces);
  } catch (error) {
    console.error(error);
  }
}

export default i18n;

