import Container from "@hellodarwin/core/lib/components/common/container";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Tabs from "@hellodarwin/core/lib/components/common/tabs";
import ProfileEditModal, {
  ProfileEditModalFunctions,
} from "@hellodarwin/core/lib/components/forms/prebuilt-forms/profile-edit-modal";
import OnlyCanadianWarningModal from "@hellodarwin/core/lib/components/forms/prebuilt-forms/profile-form/only-canadian-warning";
import ProfileCompanyForm from "@hellodarwin/core/lib/components/forms/prebuilt-forms/profile-form/profile-company-form";
import ProfileContactForm from "@hellodarwin/core/lib/components/forms/prebuilt-forms/profile-form/profile-contact-form";
import ProfileUsersList from "@hellodarwin/core/lib/components/profile/profile-users-list";
import {
  Company,
  CompanyRequest,
  Contact,
  ProfileModalProps,
} from "@hellodarwin/core/lib/features/entities";
import SingleLanguage from "@hellodarwin/core/lib/features/enums/single-language";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import notification from "antd/es/notification";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/app-hooks";
import {
  selectProfileModal,
  toggleProfileModal,
} from "../features/api/slices/global-slice";
import {
  deleteCompanyUser,
  deleteLogo,
  fetchCompany,
  fetchCompanyUsers,
  fetchContact,
  getContactByEmail,
  selectCompany,
  selectCompanyUsers,
  selectContact,
  selectProfile,
  selectProfileLoading,
  sendInvitationEmail,
  updateCompany,
  updateContact,
  updateContactById,
  updateLogo,
  updateProfile,
  upsertCompanyUser,
} from "../features/api/slices/profile-slice";
import {
  fetchIndustries,
  selectIndustriesSectors,
  selectIndustriesSubsectors,
} from "../features/api/slices/tags-slice";
import { usePartnerApi } from "../features/api/use-partner-api";

type SettingsTab = "contact" | "company" | "users";

interface ProfileSettingsPageProps {
  defaultActiveKey?: SettingsTab;
}

const ProfileSettingsPage = ({
  defaultActiveKey = "contact",
}: ProfileSettingsPageProps) => {
  const { t } = useTranslation();
  const company = useAppSelector(selectCompany);
  const contact = useAppSelector(selectContact);
  const users = useAppSelector(selectCompanyUsers);
  const dispatch = useAppDispatch();
  const api = usePartnerApi();
  const navigate = useNavigate();
  const { changeLanguage } = useLocale();

  const industrySectors = useAppSelector(selectIndustriesSectors);
  const industrySubsectors = useAppSelector(selectIndustriesSubsectors);

  useEffect(() => {
    dispatch(fetchCompanyUsers({ api }));
    dispatch(fetchContact({ api }));
    dispatch(fetchCompany({ api }));
    dispatch(fetchIndustries({ api }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabClick = (activeKey: string) => {
    navigate(`/settings/${activeKey}`);
  };

  const handleSaveContact = (newContact: Contact) => {
    try {
      const data = new FormData();
      data.append("request", JSON.stringify(newContact));

      dispatch(updateContact({ api, data }));
      notification.success({
        message: t("notification.saved"),
        placement: "top",
      });
    } catch (error) {
      notification.error({
        message: t("notification.saving_error"),
        placement: "top",
      });
    }
  };
  useEffect(() => {
    (async () => {
      try {
        await changeLanguage(
          contact?.preferred_language || SingleLanguage.English
        );
      } catch (e: any) {
        console.error(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact?.preferred_language]);

  const handleSaveCompany = (newCompany: Company) => {
    try {
      const { tagline, phone, country, ...rest } = newCompany;

      const dataCompany = new FormData();
      dataCompany.append("request", JSON.stringify(rest));
      dispatch(updateCompany({ api, data: dataCompany }));

      //Shitfix for tagline and phone
      const dataProfile = new FormData();
      const request: CompanyRequest = {
        company_id: newCompany.company_id,
        company_tagline: tagline,
        company_phone: newCompany.phone,
      };
      dataProfile.append("request", JSON.stringify(request));
      dispatch(updateProfile({ api, data: dataProfile }));

      if (country !== "Canada") {
        handleCanadianModal();
      }
      notification.success({
        message: t("notification.saved"),
        placement: "top",
      });
    } catch (error) {
      notification.error({
        message: t("notification.saving_error"),
        placement: "top",
      });
    }
  };

  const handleProfileModal = (props: ProfileModalProps) => {
    dispatch(toggleProfileModal(props));
  };
  const handleModalCancel = () => {
    dispatch(
      toggleProfileModal({ open: false, targetId: undefined, type: undefined })
    );
  };

  const modal = useAppSelector(selectProfileModal);
  const isLoading = useAppSelector(selectProfileLoading);
  const profile = useAppSelector(selectProfile);
  const functions: ProfileEditModalFunctions = {
    isLoading,
    updateLogo: ({ companyId, data }) =>
      dispatch(updateLogo({ api, data, companyId })),
    deleteLogo: ({ companyId }) => dispatch(deleteLogo({ api, companyId })),
  };

  const handleContactDelete = async (contactID: string) => {
    return await dispatch(deleteCompanyUser({ api, contactID }));
  };
  const handleContactAdd = async (contact: Contact) => {
    const data = new FormData();
    data.append("request", JSON.stringify(contact));
    return await dispatch(upsertCompanyUser({ api, data }));
  };

  const handleContactAssign = async (contact: Contact) => {
    if (contact.email) {
      return await dispatch(sendInvitationEmail({ api, email: contact.email }));
    }
  };

  const handleEmailExists = async (email: string) => {
    if (users.find((u) => u.email === email)) {
      return Promise.reject("Email already exists");
    }
    return await dispatch(getContactByEmail({ api, email }));
  };

  const handleContactEdit = async (contact: Contact) => {
    const data = new FormData();
    data.append("request", JSON.stringify(contact));

    return await dispatch(
      updateContactById({ api, contactId: contact.contact_id, data })
    );
  };

  const [canadianWarningModalOpen, setCanadianWarningModalOpen] =
    useState(false);
  const handleCanadianModal = () => {
    setCanadianWarningModalOpen(!canadianWarningModalOpen);
  };

  return (
    <PageLayout
      app="provider"
      title={t("profile|profileForm.informationTitle")}
      className="client-profile-page"
      breadcrumbs={[
        {
          breadcrumbName: t("navigation.home"),
          path: "/",
        },
        {
          breadcrumbName: t("navigation.settings"),
        },
      ]}
    >
      <Container style={{ overflow: "visible" }}>
        <Tabs
          items={[
            {
              label: t("profile|profileForm.contact.title"),
              key: "contact",
              children: (
                <ProfileContactForm
                  contact={contact}
                  handleSave={handleSaveContact}
                />
              ),
            },
            {
              label: t("profile|profileForm.company.title"),
              key: "company",
              children: (
                <ProfileCompanyForm
                  company={company}
                  toggleEditModal={handleProfileModal}
                  handleSave={handleSaveCompany}
                  isPartner
                  industrySectors={industrySectors}
                  industrySubsectors={industrySubsectors}
                />
              ),
            },
            {
              label: t("profile|profileForm.users.title"),
              key: "users",
              children: (
                <ProfileUsersList
                  contactId={contact.contact_id}
                  companyId={company.company_id}
                  users={users}
                  handleContactDelete={handleContactDelete}
                  handleContactAdd={handleContactAdd}
                  handleContactAssign={handleContactAssign}
                  handleContactEdit={handleContactEdit}
                  handleEmailExists={handleEmailExists}
                  destination={"Partner"}
                  isLoading={isLoading}
                  isAccountManager={contact.roles?.includes("Account Manager")}
                />
              ),
            },
          ]}
          defaultActiveKey={defaultActiveKey}
          onTabClick={handleTabClick}
        />
      </Container>

      <ProfileEditModal
        functions={functions}
        handleCancel={handleModalCancel}
        modal={modal}
        profile={profile}
      />
      <OnlyCanadianWarningModal
        open={canadianWarningModalOpen}
        handleClose={handleCanadianModal}
      />
    </PageLayout>
  );
};

export default ProfileSettingsPage;

