import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import parse from "@hellodarwin/core/lib/features/helpers/parse";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import Checkbox from "antd/es/checkbox/Checkbox";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { useAppSelector } from "../../../app/app-hooks";
import {
  selectMatchesLoading,
  selectSelectedMatch,
} from "../../../features/api/slices/matches-slice";

type PaymentFormProps = {
  onCancel: () => void;
  handleFormConfirm: () => void;
  availableRaisehands: number;
  setWaiveFee: (state: boolean) => void;
  waiveFee: boolean;
  helloMessage?: string;
  setHelloMessage: (state: string) => void;
};

const PaymentForm = ({
  onCancel,
  handleFormConfirm,
  availableRaisehands,
  setWaiveFee,
  waiveFee,
  helloMessage,
  setHelloMessage,
}: PaymentFormProps) => {
  const { t } = useTranslation();
  const loading = useAppSelector(selectMatchesLoading);

  const match = useAppSelector(selectSelectedMatch);

  const handleChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setHelloMessage(e.currentTarget.value);
  };

  if (!match.project) return <></>;
  const Header = () => (
    <Div>
      <Typography.Title level={2} elementTheme="h4">
        👋 {t("modal|raiseHand.paymentForm.title")}
      </Typography.Title>

      <Div flex={"row"} style={{ marginBottom: "1rem" }}>
        <Typography elementTheme="caption">
          {parse(
            t("modal|raiseHand.paymentForm.text", {
              leadPrice:
                (match.lead_price || 0) - (match.discounted_amount || 0),
              raiseHandPrice:
                waiveFee || match.source === "Directory"
                  ? 0
                  : match.project.raise_hand_price || 0,
            })
          )}
        </Typography>
      </Div>
    </Div>
  );
  const Footer = () => (
    <Div
      flex={"row"}
      gap={16}
      justify={"space-between"}
      style={{ marginTop: "2rem" }}
      align="center"
    >
      {availableRaisehands > 0 && (
        <Div fitContent>
          <label>
            <Div
              flex="row"
              align="center"
              gap={8}
              className="waive-button"
              style={{ cursor: "pointer" }}
            >
              <Checkbox
                onClick={() => setWaiveFee(!waiveFee)}
                checked={waiveFee}
                className="hd-checkbox"
              />

              <Typography elementTheme="body2">
                {availableRaisehands <= 1
                  ? t("modal|raiseHand.paymentForm.freeRaiseHand", {
                      count: availableRaisehands,
                    })
                  : t("modal|raiseHand.paymentForm.freeRaiseHandPlural", {
                      count: availableRaisehands,
                    })}
              </Typography>
            </Div>
          </label>
        </Div>
      )}
      <Div
        flex={"row"}
        tablet={{ flex: "column-reverse" }}
        gap={16}
        align="center"
        justify="flex-end"
      >
        <Button
          defaultStyle={theme.colors.white_1}
          onClick={onCancel}
          size="small"
        >
          {t("common|button.cancel")}
        </Button>
        <Button
          defaultStyle={theme.colors.green_1}
          onClick={handleFormConfirm}
          loading={loading}
          size="small"
        >
          {t("modal|raiseHand.paymentForm.raiseHand", {
            raiseHandPrice:
              waiveFee || match.source === "Directory"
                ? 0
                : match.project.raise_hand_price || 0,
          })}
        </Button>
      </Div>
    </Div>
  );
  return (
    <Div flex={"column"} gap={16}>
      <Header />
      <Div flex={"row"}>
        <TextArea
          placeholder={t("modal|raiseHand.paymentForm.placeHolder")}
          rows={7}
          value={helloMessage}
          onChange={handleChange}
          maxLength={500}
          style={{ width: "100%" }}
          showCount
        />
      </Div>
      <Footer />
    </Div>
  );
};

export default PaymentForm;

