import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import Modal from "@hellodarwin/core/lib/components/common/hd-modal";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import GreenCheck from "@hellodarwin/icons/dist/icons/GreenCheck";
import { CSSProperties } from "react";

type ProjectReferralModalProps = {
  isVisible: boolean;
  handleCancel: () => void;
};

const modalBodyStyle: CSSProperties = {
  padding: "2.5rem",
};

const ProjectReferralModal = ({
  isVisible,
  handleCancel,
}: ProjectReferralModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      className={"project-referral-modal-container"}
      open={isVisible}
      handleCancel={handleCancel}
      destroyOnClose
      styles={{ body: modalBodyStyle }}
    >
      <Div
        className={"project-referral-modal-content"}
        flex={"column"}
        justify={"center"}
        align={"center"}
        gap={24}
      >
        <GreenCheck width={90} height={90} />

        <Div flex="column" gap={12}>
          <Typography.Title level={2} elementTheme="h4" textAlign={"center"}>
            {t("referral|successModal.title")}
          </Typography.Title>
          <Typography textAlign={"center"}>
            {t("referral|successModal.message")}
          </Typography>
        </Div>
        <Button defaultStyle={theme.colors.purple_1} onClick={handleCancel}>
          {t("referral|successModal.button")}
        </Button>
      </Div>
    </Modal>
  );
};

export default ProjectReferralModal;

