import { IconProps } from "../features/entities/general";

const Locked = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.72 13.7846V8.24613C22.72 6.48346 22.012 4.79298 20.7517 3.54659C19.4915 2.30019 17.7822 1.59998 16 1.59998C14.2177 1.59998 12.5085 2.30019 11.2482 3.54659C9.98799 4.79298 9.27999 6.48346 9.27999 8.24613V13.7846M8.15999 30.4H23.84C24.7311 30.4 25.5857 30.0499 26.2159 29.4267C26.846 28.8035 27.2 27.9582 27.2 27.0769V17.1077C27.2 16.2263 26.846 15.3811 26.2159 14.7579C25.5857 14.1347 24.7311 13.7846 23.84 13.7846H8.15999C7.26886 13.7846 6.41423 14.1347 5.78411 14.7579C5.15399 15.3811 4.79999 16.2263 4.79999 17.1077V27.0769C4.79999 27.9582 5.15399 28.8035 5.78411 29.4267C6.41423 30.0499 7.26886 30.4 8.15999 30.4Z"
          stroke="currentColor"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default Locked;
