import { IconProps } from "../features/entities/general";

const Attachment = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.6667 11.0806L22.5579 24.9664C22.546 26.4897 21.9081 27.946 20.7847 29.015C19.6613 30.0839 18.1443 30.678 16.5674 30.6665C14.9905 30.655 13.483 30.0388 12.3764 28.9535C11.2699 27.8683 10.6549 26.4028 10.6668 24.8795L10.8215 5.13285C10.8256 4.62998 10.9321 4.13281 11.1351 3.66972C11.338 3.20663 11.6334 2.78669 12.0044 2.43388C12.3754 2.08107 12.8146 1.8023 13.2971 1.61348C13.7796 1.42467 14.2959 1.32951 14.8164 1.33343C15.337 1.33735 15.8516 1.44028 16.331 1.63635C16.8104 1.83241 17.2451 2.11777 17.6103 2.47613C17.9755 2.83449 18.2641 3.25882 18.4596 3.72492C18.655 4.19101 18.7535 4.68973 18.7495 5.1926L18.5947 24.9537C18.5798 25.4557 18.3604 25.931 17.9855 26.2788C17.6105 26.6267 17.1091 26.8183 16.5893 26.8123C16.0695 26.8064 15.5729 26.6034 15.2066 26.2471C14.8402 25.8908 14.6335 25.4098 14.6309 24.9076L14.7413 10.8106M18.5939 24.9366L18.5938 24.9546"
          stroke="currentColor"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default Attachment;
