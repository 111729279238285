import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";

type RaiseHandSuccessProps = {
  handleSuccessCancel: () => void;
};

const RaiseHandSuccess = ({ handleSuccessCancel }: RaiseHandSuccessProps) => {
  const { t } = useTranslation();

  return (
    <Div flex={"column"} justify={"center"} align={"center"} gap={32}>
      <Div
        flex={"row"}
        tablet={{ flex: "column", gap: 16 }}
        align={"center"}
        gap={32}
      >
        <Typography elementTheme="h1">🎉</Typography>
        <Div flex={"column"}>
          <Typography.Title level={3} elementTheme="h4" noMargin={false}>
            {t("modal|raiseHand.paymentSuccess.title")}
          </Typography.Title>
          <Typography elementTheme="body2">
            {t("modal|raiseHand.paymentSuccess.text")}
          </Typography>
        </Div>
      </Div>
      <Div flex={"row"} justify={"flex-end"}>
        <Button
          defaultStyle={theme.colors.yellow_1}
          onClick={handleSuccessCancel}
          size="small"
        >
          {t("modal|raiseHand.paymentSuccess.ok")}
        </Button>
      </Div>
    </Div>
  );
};

export default RaiseHandSuccess;

